import React, { useEffect, useState} from 'react';
import {
    Button,
    Box,
    IconButton,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Checkbox,
    Flex,
    Text,
    useToast,
    Input,
} from '@chakra-ui/react';
import {
    EditIcon,
    ChevronUpIcon,
    ChevronDownIcon,
    SettingsIcon,
} from '@chakra-ui/icons';
import styled from 'styled-components';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Button as Btn, Responsive, Select as Slct, Flex as Flx, Pill, Loading } from '../../../components';
import axiosHelper from '../../../core/axios-helper';
import { navigate } from 'gatsby';
import { getMerchantID, useMerchantAuth } from '../../../core/auth';
import { filterAndSortTransactions, SORT_DIRECTON } from '../utils';
import { COLOR } from '../../../components/theme';
import { partnerHubServiceBaseUrl } from '../../../core/config';
import { utcTimeDate } from '../../../utils/dateFormat';

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const StyledTableHeader = styled.th`
  text-align: left;
  padding: 8px;
  cursor: pointer;
  display: table-cell;
//   &:hover {
//     background: ${COLOR.DARK_SKY_BLUE};
//   }
//     color: ${({ isSorted }) => (isSorted ? 'blue' : 'inherit')};
`;

const StyledTableRow = styled.tr`
  border-bottom: 1px solid #ddd;
  &:hover {
    background-color: #f9f9f9;
  }
`;

const StyledTableCell = styled.td`
  padding: 8px;
  white-space: normal; /* Allow text to wrap */
  word-wrap: break-word;
  word-break: break-word;
`;

const INITIAL_SEARCH_CONFIG = {
    resultsPerPage: 25,
    nextToken: null,
};

const RESULT_PER_PAGE_OPTIONS = [
    { label: "25", value: "25" },
    { label: "50", value: "50" },
    { label: "100", value: "100" },
    { label: "200", value: "200" },
  ];

const SampleDataGrid = ({
    merchantId = 'defaultMerchant',
    baseUrl = 'YOUR_API_BASE_URL',
}) => {
    const [data, setData] = useState([]);
    const [displayData, setDisplayData] = useState([]);
    const [searchConfig, setSearchConfig] = useState(INITIAL_SEARCH_CONFIG);
    const [search, setSearch]= useState();
    const [fetchingOrders, setFetchingOrders] = useState(true);
    const [currentSort, setCurrentSort] = useState({
        field: 'firstName', // Default sort field
        direction: 'descending', // Default sort direction
    });
    const [currentIndex, setCurrentIndex] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [allColumns, setAllColumns] = useState(['firstName', 'lastName','email', 'isActive', 'trainingCompletedAt', 'actions']);
    const [columns, setColumns] = useState(['firstName', 'lastName','email', 'isActive', 'trainingCompletedAt', 'actions']);
    const [visibleColumns, setVisibleColumns] = useState(allColumns);
    const [disabled, setDisabled] = useState(false);
    const toast = useToast();
    const { profile } = useMerchantAuth();
      const merchantID = getMerchantID();
    const changeResultsPerPage = (_, value) => {
        setSearchConfig({ resultsPerPage: value, nextToken: null });
        if (disabled) {
            setDisabled(false);
        }
    };

    const changeNextToken = (value) =>
        setSearchConfig((prevSearchConfig) => ({
            ...prevSearchConfig,
            nextToken: value,
        }));

    const handleSortChange = (value) => {
       if (currentSort?.field === value) {
            const newSortDirection =
              currentSort?.direction === SORT_DIRECTON.DESC
                ? SORT_DIRECTON.ASC
                : SORT_DIRECTON.DESC;
      
            setCurrentSort({ ...currentSort, direction: newSortDirection });
            return;
          }
      
          setCurrentSort({ field: value, direction: SORT_DIRECTON.DESC });
    };

    const handlePrevClick = () => {
        const newStartIndex = currentIndex - Number(searchConfig?.resultsPerPage);
        if (newStartIndex < 0) {
            return;
        }
        setCurrentIndex(newStartIndex);
        if (searchConfig?.nextToken === '' && disabled === true) {
            setDisabled(false);
        }
    };

    const handleNextClick = async () => {
        const newStartIndex = currentIndex + Number(searchConfig?.resultsPerPage);
        // if (newStartIndex >= displayData?.length && searchConfig?.nextToken !== '') {
        //     setDisabled(false);
        //     await refreshOrders(searchConfig?.nextToken);
        //     setCurrentIndex(newStartIndex);
        //     return;
        // }
        setCurrentIndex(newStartIndex);
    };

    const refreshOrders = async (pageToken) => {
        setFetchingOrders(true);
        let url = `${partnerHubServiceBaseUrl}/accounts?merchantId=${merchantID}&maxItems=${searchConfig.resultsPerPage}`;
        if (search && searchTerm.trim() !== '') {
            url += `&email=${encodeURIComponent(searchTerm.trim())}`;
          }

        if (pageToken && pageToken.trim() !== '') {
          url += `&nextToken=${pageToken}`;
        }
        try {
            const response = await axiosHelper.withMerchantAuth.get(url);
            const transformedOrders = response?.data?.accounts ?? [];
            setFetchingOrders(false);
            setData((prevOrders) => [...prevOrders, ...transformedOrders]);
            setDisabled(false);
            // if (response?.data?.nextToken) {
            //     setDisabled(false);
            //     changeNextToken(response?.data?.nextToken);
            // } else {
            //     changeNextToken('');
            //     setDisabled(true);
            // }
            if (response?.data?.accounts?.length < searchConfig.resultsPerPage) {
                setDisabled(true);
            }
        } catch (err) {
            setFetchingOrders(false);
        }
    };

    useEffect(() => {
        if (
            displayData?.slice(
                currentIndex,
                currentIndex + Number(searchConfig?.resultsPerPage)
            ).length < searchConfig?.resultsPerPage
        ) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    }, [currentIndex, searchConfig.resultsPerPage, currentSort.field, currentSort.direction]);

    useEffect(() => {
        setData([]);
        refreshOrders('');
    }, [searchConfig.resultsPerPage, search]);

    useEffect(() => {
        let orders=data
        const filterAndSortOrders = async () => {
          const newDisplayOrders = filterAndSortTransactions({
                  orders,
                  filterText: "",
                  extractSortField: (currRow) =>
                     currRow?.[currentSort.field],
                  sortDirection: currentSort?.direction,
                });

            setCurrentIndex(0);
            setDisplayData(newDisplayOrders);
            if(profile?.features?.isUserUpdateEnabled===true ){
                setVisibleColumns(['firstName', 'lastName','email','isActive', 'trainingCompletedAt', 'actions'])
                setAllColumns(['firstName', 'lastName','email','isActive', 'trainingCompletedAt', 'actions'])
                setColumns(['firstName', 'lastName','email','isActive', 'trainingCompletedAt', 'actions'])
            }else{
                setVisibleColumns(['firstName', 'lastName','email','isActive', 'trainingCompletedAt'])
                setAllColumns(['firstName', 'lastName','email','isActive', 'trainingCompletedAt'])
                setColumns(['firstName', 'lastName','email','isActive', 'trainingCompletedAt'])
            }
        };

        filterAndSortOrders();
    }, [data, currentSort.field, currentSort.direction, search]);
// const onDelete=async(user)=>{
//     const response = await axiosHelper.withMerchantAuth.delete(
//               `https://2010ece4-887c-4f6e-bad1-ae4ffd8eb4dd.mock.pstmn.io/account`,
//               user
//             );
//             // if (response.status !== 200) {
//             //   // logger.error("Error while fetching transactions", response.data);
//             //   toast({
//             //     title: 'Api Called Failed',
//             //     description: response.data,
//             //     status: 'error',
//             //     duration: 3000,
//             //     isClosable: true,
//             //     position: 'top-right',
//             // })
          
//             // }else{
//             //     toast({
//             //         title: 'User Deleted Successfully',
//             //         description: response.data,
//             //         status: 'success',
//             //         duration: 3000,
//             //         isClosable: true,
//             //         position: 'top-right',
//             //     })
//             // }
//             refreshOrders(searchConfig?.nextToken)
// }
const onAdd = ()=>{
    navigate('/merchant/manageusers/create')
}
const onEdit =(id)=>{
    navigate(`/merchant/manageusers/edit/${id}`)
}
    const getSortIcon = (column) => {
        if (currentSort.field !== column) return null;
        return currentSort.direction === 'ascending' ? (
            <ChevronUpIcon />
        ) : (
            <ChevronDownIcon />
        );
    };

    const onDragEnd = (result) => {
        if (!result.destination) return;

        const items = Array.from(columns);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        setColumns(items);
    };

    const handleColumnVisibility = (column) => {
        if (visibleColumns.includes(column)) {
            setVisibleColumns(visibleColumns.filter((c) => c !== column));
        } else {
            setVisibleColumns([...visibleColumns, column]);
        }
    };

    return (
        <Box>
            <Flex mb={4} justifyContent="space-between" alignItems="center">
                <Flex alignItems="center" gap={2}>
                   {(profile?.features?.isUserCreateEnabled===true || profile?.features?.isUserUpdateEnabled===true)&&  <Button colorScheme="green" onClick={onAdd}>
                        Add New User
                     </Button> }
            {/* //   <Button colorScheme="green" onClick={onAdd}>
            //             Add New User
            //         </Button>  */}
                    
                </Flex>
                <div>
                <Input
                        placeholder="Search by email."
                        size="lg"
                        width="300px"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        mr={4}
                />
                
                <Button size="md" colorScheme="blue" onClick={()=>setSearch(true)}  mr={4}>Search</Button>
                <Button size="md" colorScheme="blue" onClick={()=>{
                    setSearchTerm('')
                    setSearch(false)
                    window.location.reload()
                }
                }>Clear</Button>
                </div>
                <Menu>
                    <MenuButton as={IconButton} icon={<SettingsIcon />} aria-label="Column Settings" />
                    <MenuList>
                        {allColumns.map((column) => (
                            <MenuItem key={column}>
                                <Checkbox
                                    isChecked={visibleColumns.includes(column)}
                                    onChange={() => handleColumnVisibility(column)}
                                >
                                    {column.charAt(0).toUpperCase() + column.slice(1)}
                                </Checkbox>
                            </MenuItem>
                        ))}
                    </MenuList>
                </Menu>
            </Flex>

            {fetchingOrders && (
                <Flex justifyContent="center" my={4}>
                   <Loading />
                </Flex>
            )}

            {!fetchingOrders && displayData?.length === 0 && (
                <Flex justifyContent="center" my={4}>
                    <Text>No records found.</Text>
                </Flex>
            )}

            {!fetchingOrders && displayData?.length > 0 && (
                <>
                
                          
                                <StyledTable>
                                    <thead>
                                        <StyledTableRow>
                                            {columns.filter((column) => visibleColumns.includes(column)).map((column, index) => (
                                               
                                                        <StyledTableHeader
                                                            onClick={column !== 'actions' ? () => handleSortChange(column) : undefined}
                                                            isSorted={currentSort.field === column}
                                                        >
                                                            {column.charAt(0).toUpperCase() + column.slice(1)}
                                                            {column !== 'actions' && getSortIcon(column)}
                                                   
                                                        </StyledTableHeader>
                                                 
                                            ))}
                                        </StyledTableRow>
                                    </thead>
                                    <tbody>
                                        {displayData
                                            ?.slice(
                                                currentIndex,
                                                currentIndex + Number(searchConfig?.resultsPerPage)
                                            )
                                            .map((user) => (
                                                <StyledTableRow key={user.id}>
                                                    {columns.filter((column) => visibleColumns.includes(column)).map((column) => (
                                                        <StyledTableCell key={`${user.id}-${column}`}>
                                                            {column === 'actions' ? (
                                                                <>
                                                                    <IconButton
                                                                        aria-label="Edit user"
                                                                        icon={<EditIcon />}
                                                                        onClick={() => onEdit(user.email)}
                                                                        size="sm"
                                                                        mr={2}
                                                                    />
                                                                    {/* <IconButton
                                                                        aria-label="Delete user"
                                                                        icon={<DeleteIcon />}
                                                                        onClick={() => onDelete(user)}
                                                                        size="sm"
                                                                    /> */}
                                                                </>
                                                            ) : (
                                                                column==='isActive'? user[column]=== true ? <Pill compact green>
                                                                             Active
                                                                            </Pill> : <Pill compact red>
                                                                             Deactivated
                                                                            </Pill>: column==='trainingCompletedAt'? <>{user['trainingCompletedAt']===''? user['trainingCompletedAt']:<>{utcTimeDate(user['trainingCompletedAt'])}</>}</>: user[column]
                                                            )}
                                                        </StyledTableCell>
                                                    ))}
                                                </StyledTableRow>
                                            ))}
                                    </tbody>
                                </StyledTable>
                    
                     

                    <Responsive margin="32px 12px 0 0">
                        <Flex>
                            <Btn
                                smallFont
                                showButton
                                variant="secondary"
                                margin="0 12px 0 0"
                                disabled={currentIndex === 0}
                                onClick={handlePrevClick}
                            >
                                &lt; Prev
                            </Btn>

                            <Btn
                                smallFont
                                showButton
                                variant="secondary"
                                loading={fetchingOrders}
                                disabled={disabled}
                                onClick={handleNextClick}
                            >
                                Next &gt;
                            </Btn>
                        </Flex>

                        <Flx alignContent="center">
                            <Text color="grey.t75" margin="0 4 0 0">
                                <strong>Results per page: </strong>
                            </Text>

                            <Slct
                                grey
                                name="resultsPerPage"
                                color="silver"
                                selected={searchConfig?.resultsPerPage}
                                options={RESULT_PER_PAGE_OPTIONS}
                                onChange={changeResultsPerPage}
                            />
                        </Flx>
                    </Responsive>
                </>
            )}
        </Box>
    );
};

export default SampleDataGrid;