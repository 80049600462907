import React from "react";
import { Container, Grid, Flex, Link, Stack, Text } from "basis";
import { NavigationalLayout } from "../components";
import DataGrid from "./DataGrid";



const  Scene= () => {
  return (
    <NavigationalLayout>
      <Grid preset="page" rowsGap={8}>

        <Grid.Item colSpan="all">
          <Text as="h3" textStyle="heading3">User Dashboard</Text>
        </Grid.Item>

        <Grid.Item colSpan="all">
          <Container bg="white" padding="8">
            <DataGrid/>
          </Container>
        </Grid.Item>

      </Grid>
    </NavigationalLayout>
  );
};

export default Scene;
